import {
    AnyAction,
    createSlice,
    Dispatch,
    Middleware,
    MiddlewareAPI,
    PayloadAction,
} from "@reduxjs/toolkit";
import {selectSessionID, selectWebSocketURL} from "./system";
import {RootState} from "../rootReducer";
import {default as Sockette, SocketteOptions} from "sockette";
import EventEmitter from "events";
import {nanoid} from "nanoid";
import {AppDispatch, AppThunk} from "../store";
import { campaignWsActions } from "./campaign";

const webSocketTimeout = 5000;

export type ReplicatedAction<T> = PayloadAction<T, string, { isReplicated: boolean }>

export type RequestHandler = (type: string, payload: any) => AppThunk | AnyAction
export type ActionMap = { [key: string]: RequestHandler };

const RootActionMap: ActionMap = {
    ...campaignWsActions,
};

type ReqHello = {
    sessionID: string,
};

type ReqGenericSubscribe = {
    id: string,
};

type ReqGenericUnsubscribe = {
    id: string,
};

type RspGeneric = {
    status: string,
    error_message?: string,
};

type WebSocketMessage = {
    id: string,
    type: string,
    frameType: string,
    payload: any,
};

export type ResponseHandler = (store: MiddlewareAPI, msg: any) => void;

export type WebSocketRequestParams = {
    type: string,
    payload: any,
    responseHandler?: ResponseHandler,
};

export type WebSocketAutoParams = {
    id: string,
    action: string,
};

export type WebSocketAutoRequestParams = WebSocketRequestParams & {
    auto: WebSocketAutoParams,
};

type WebSocketResponder = {
    resolve: (value: WebSocketMessage) => void,
    reject: (reason?: any) => void,
};

type PendingRequest = any;

type AutoHandler = () => void;

class MBWebSocketClient extends EventEmitter {
    ws: Sockette | undefined = undefined
    autoActions: Map<string, AutoHandler>
    responders: Map<string, WebSocketResponder>
    debug: boolean = true
    socketteOptions: SocketteOptions
    isConnected: boolean = false
    isAuthenticated: boolean = false
    sessionID: string | undefined = undefined
    pendingRequests = new Array<PendingRequest>()

    constructor() {
        super();

        this.responders = new Map<string, WebSocketResponder>()
        this.autoActions = new Map<string, AutoHandler>()

        this.socketteOptions = {
            timeout: webSocketTimeout,
            onopen: e => this.handleOpen(e),
            onreconnect: e => this.emit("reconnect"),
            onmaximum: e => this.emit("maximum"),
            onclose: e => this.handleClose(e),
            onmessage: e => this.handleMessage(e),
            onerror: e => this.emit("error", e),
        };
    }

    connect(url: string, sessionID: string) {
        if (this.ws !== undefined) {
            return
        }

        this.ws = new Sockette(url, this.socketteOptions);
        this.sessionID = sessionID
    }

    drain() {
        if (!this.isAuthenticated) {
            return;
        }

        this.pendingRequests.forEach((req) => {
            this.json(req);
        });

        this.pendingRequests = new Array<PendingRequest>()
    }

    json(data: any) {
        if (this.debug) {
            console.log("Sending WebSocket message", data);
        }
        this.ws?.json(data);
    }

    handleOpen(event: Event) {
        this.isConnected = true;

        if (this.sessionID !== undefined) {
            let req: ReqHello = {
                sessionID: this.sessionID,
            };
            this.sendRequest("system/hello", req, true).then(() => {
                this.isAuthenticated = true;
                this.emit("authenticated");
                this.drain();
                this.runAutoActions();
            });
        }

        this.emit("open");
    }

    handleClose(event: CloseEvent) {
        this.responders.forEach((r) => {
            r.reject("socket closed before response received");
        });
        this.responders.clear();

        this.isAuthenticated = false;
        this.isConnected = false;
        this.emit("close", event.code, event);
    }

    handleMessage(event: MessageEvent<any>) {
        // XXX TODO validate
        let data = event.data;
        let msg = JSON.parse(data) as WebSocketMessage;
        if (this.debug) {
            console.log("WebSocket message received:", msg);
        }
        if (msg.frameType === "reply") {
            let responder = this.responders.get(msg.id);
            if (responder === undefined) {
                console.log("Ignoring reply message for which there is no responder", msg);
                return;
            }

            responder.resolve(msg);
            this.responders.delete(msg.id);
            return;
        }
        else if (msg.frameType === "event") {
            this.emit("event", msg);
        }
        else if (msg.frameType === "request") {
            this.emit("request", msg);
        }
    }

    sendRequest(type: string, payload: any, immediate?: boolean): Promise<WebSocketMessage> {
        return new Promise<WebSocketMessage>((resolve, reject) => {
            let req: WebSocketMessage = {
                id: nanoid(),
                frameType: "request",
                type: type,
                payload: payload,
            };

            let responder: WebSocketResponder = {
                resolve: resolve,
                reject: reject,
            };
            this.responders.set(req.id, responder);

            if (immediate === true || this.isAuthenticated) {
                if (this.debug) {
                    console.log("Immediately sending WebSocket message", req);
                }
                this.json(req);
            } else {
                if (this.debug) {
                    console.log("Holding WebSocket message until connected and authenticated", req);
                }
                this.pendingRequests.push(req);
            }
        });
    }

    addAutoAction(name: string, handler: AutoHandler) {
        this.autoActions.set(name, handler)
    }

    deleteAutoAction(name: string) {
        this.autoActions.delete(name)
    }

    runAutoActions() {
        this.autoActions.forEach((autoHandler) => {
            autoHandler();
        });
    }
}

type ActionWhitelist = {
    [key: string]: number
};

type WebsocketState = {
    connected: boolean,
    actionWhitelist: ActionWhitelist,
};

const initialState: WebsocketState = {
    connected: false,
    actionWhitelist: {},
};

export const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        connect: (state, action: PayloadAction<void>) => {
        },
        setConnected: (state, action: PayloadAction<boolean>) => {
            state.connected = action.payload;
        },
        sendRequest: (state, action: PayloadAction<WebSocketRequestParams>) => {
        },
        sendAutoRequest: (state, action: PayloadAction<WebSocketAutoRequestParams>) => {
        },
    },
});

export const websocketActions = websocketSlice.actions;

export default websocketSlice.reducer;

export const selectWebSocket = (state: RootState) => state.websocket;
export const selectConnected = (state: RootState) => selectWebSocket(state).connected;

function replicateActionToServer(socket: MBWebSocketClient, next: Dispatch, action: any) {
    socket.sendRequest(action.type, action.payload).then((rsp) => {
        let rg = rsp.payload as RspGeneric;
        if (rg.status === "ok") {
            return next(action)
        }
    });
}

export type SubscribeMessageBuilder = (...args: any[]) => [msgType: string, msg: any]
export type SubscribeActionCreatorParams = {
    itemType: string,
    subscribeMsg?: SubscribeMessageBuilder,
    unsubscribeMsg?: SubscribeMessageBuilder,
    subscribeResponse?: ResponseHandler,
    unsubscribeResponse?: ResponseHandler,
};

export type SubscribeThunkCreator = (identity: string) => AppThunk;

export function createSubscribeActionCreator(
    {itemType, subscribeMsg, unsubscribeMsg, subscribeResponse, unsubscribeResponse}: SubscribeActionCreatorParams):
    [SubscribeThunkCreator, SubscribeThunkCreator] {
    let refCount = 0;

    // TODO implement some kind of level system so we can upgrade

    let sMsg: SubscribeMessageBuilder;
    if (subscribeMsg === undefined) {
        sMsg = (identity: string) => {
            let msgType = `${itemType}/subscribe`;
            let msg: ReqGenericSubscribe = {
                id: identity,
            };

            return [msgType, msg];
        };
    } else {
        sMsg = subscribeMsg;
    }

    let usMsg: SubscribeMessageBuilder;
    if (unsubscribeMsg === undefined) {
        usMsg = (identity: string) => {
            let msgType = `${itemType}/unsubscribe`;
            let msg: ReqGenericSubscribe = {
                id: identity,
            };

            return [msgType, msg];
        };
    } else {
        usMsg = unsubscribeMsg;
    }

    let createSubscribeThunk = (identity: string, ...args: any[]): AppThunk => {
        let subscribeThunk: AppThunk = (dispatch, getState) => {
            if (++refCount == 1) {
                // Send subscribe message
                let [msgType, msg] = sMsg(identity, ...args);
                dispatch(websocketActions.sendAutoRequest({
                    type: msgType,
                    payload: msg,
                    responseHandler: subscribeResponse,
                    auto: {
                        id: `${itemType}/${identity}`,
                        action: "add",
                    },
                }));
            }
        };
        return subscribeThunk;
    }

    let createUnsubscribeThunk = (identity: string): AppThunk => {
        let unsubscribeThunk: AppThunk = (dispatch, getState) => {
            if (--refCount == 0) {
                // Send unsubscribe message
                let [msgType, msg] = usMsg(identity);
                dispatch(websocketActions.sendAutoRequest({
                    type: msgType,
                    payload: msg.payload,
                    responseHandler: unsubscribeResponse,
                    auto: {
                        id: `${itemType}/${identity}`,
                        action: "delete",
                    },
                }));
            }
        };
        return unsubscribeThunk;
    }

    return [createSubscribeThunk, createUnsubscribeThunk];
}

function handleRequest(store: MiddlewareAPI, msg: WebSocketMessage) {
    // Check whitelist for action
    let handler = RootActionMap[msg.type];
    if (handler === undefined) {
        return;
    }

    // Dispatch action
    let action = handler(msg.type, msg.payload);

    // Dispatch thunk
    let dispatch = store.dispatch as AppDispatch;
    dispatch(action);
}

function makeSocket(store: MiddlewareAPI): MBWebSocketClient {
    let socket = new MBWebSocketClient();

    socket.on("open", function () {
        console.log("connected to websocket");
    });

    socket.on("authenticated", function () {
        console.log("authenticated");
        store.dispatch(websocketActions.setConnected(true));
    });

    socket.on("close", function (code: number, event: any) {
        console.log("close event", code, event);
        store.dispatch(websocketActions.setConnected(false));
    });

    socket.on("request", function (msg: WebSocketMessage) {
        handleRequest(store, msg);
    });

    socket.on("event", function (msg: WebSocketMessage) {
        handleRequest(store, msg);
    });

    socket.on("error", function (event) {
        console.log("websocket error", event);
    });

    return socket;
}

async function handleSendRequest(socket: MBWebSocketClient, store: MiddlewareAPI,
                                 action: PayloadAction<WebSocketRequestParams>) {
    let rsp = await socket.sendRequest(action.payload.type, action.payload.payload);
    if (action.payload.responseHandler !== undefined) {
        action.payload.responseHandler(store as MiddlewareAPI, rsp.payload);
    }
}

async function handleSendAutoRequest(socket: MBWebSocketClient, store: MiddlewareAPI,
                                     action: PayloadAction<WebSocketAutoRequestParams>) {
    await handleSendRequest(socket, store, action);

    let autoAction = action.payload.auto.action;
    let autoId = action.payload.auto.id;
    if (autoAction === "add") {
        socket.addAutoAction(autoId, () => {
            handleSendRequest(socket, store, action);
        });
    }
    else if (autoAction == "delete") {
        socket.deleteAutoAction(autoId);
    }
}

export function makeWebSocketMiddleware(): Middleware<{}, RootState> {
    return store => {
        let socket = makeSocket(store);

        return next => async action => {
            if (websocketActions.connect.match(action)) {
                let url = selectWebSocketURL(store.getState());
                let sessionID = selectSessionID(store.getState());
                socket.connect(url, sessionID);
            } else if (websocketActions.sendRequest.match(action)) {
                await handleSendRequest(socket, store as MiddlewareAPI, action);
            } else if (websocketActions.sendAutoRequest.match(action)) {
                await handleSendAutoRequest(socket, store as MiddlewareAPI, action);
            } else if (action?.meta?.isReplicated === true) {
                return replicateActionToServer(socket, next, action);
            }

            return next(action);
        };
    }
}
