import { combineReducers } from '@reduxjs/toolkit';
import userReducer from "./store/user";
import campaignReducer from "./store/campaign";
import systemReducer from "./store/system";
import websocketReducer from "./store/websocket";

const rootReducer = combineReducers({
    user: userReducer,
    campaign: campaignReducer,
    system: systemReducer,
    websocket: websocketReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
