import {Link, useLocation, useNavigate} from "react-router-dom";
import React from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import "./Recover.scss";
import {Notify} from "../../utils/notifications";
import {AxiosError} from "axios";
import {getAxios} from "../../utils/axios";

type RecoverForm = {
    email: string;
};

function Recover() {
    const location = useLocation();
    const navigate = useNavigate();

    // Build recover form
    const recoverSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
    })
    const {
        register: recoverRegister,
        handleSubmit: recoverHandleSubmit,
        formState: recoverFormState,
        setValue: recoverSetValue,
        watch: recoverWatch,
    } = useForm<RecoverForm>({
        resolver: yupResolver(recoverSchema)
    });
    const recoverErrors = recoverFormState.errors;
    const email = recoverWatch("email", location.state?.email);

    useEffect(() => {
        if (email) {
            recoverSetValue("email", email, { shouldValidate: true });
            window.history.replaceState({}, document.title);
        }
    }, []);

    // Get axios
    const axios = getAxios();

    async function onSubmit (req: RecoverForm) {
        try {
            const { data } = await axios.post(
                "/api/auth/recover",
                req,
            );

            if (data.status === "failure") {
                //shakeLoginForm();
                Notify.warning(data.message);
            } else if (data.status === "success") {
                Notify.info(data.message);
                navigate("/auth", { state: { email }});
            } else {
                Notify.error("Bad response from server")
                console.log(data);
            }

        } catch (error) {
            if (error instanceof AxiosError) {
                const axiosError: AxiosError = error;
                Notify.error(axiosError.message);
            } else {
                Notify.error(String(error));
            }
        }
    }

    const onError = () => {
        //shakeLoginForm();
    };

    return (
        <div className="form-recover">
            <div className={`form-body shadow-sm bg-body rounded vw-50`}>
                <form onSubmit={recoverHandleSubmit(onSubmit, onError)}>
                    <div className="mb-3">
                        <label htmlFor="recoverEmail" className="form-label">Email address</label>
                        <input type="email" id="recoverEmail"
                               className={`form-control ${recoverErrors.email ? 'is-invalid' : ''}`}
                               {...recoverRegister("email")} />
                        <div className="invalid-feedback">{recoverErrors.email?.message}</div>
                    </div>
                    <div className="dialog-buttons">
                        <div className="dialog-buttons-left" >
                            <button type="submit" className="btn btn-primary">Recover Account</button>
                        </div>
                        <div className="dialog-buttons-right" >
                            <Link to="/auth" state={{email: email}}>Cancel</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Recover;
