import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Notify} from "../../utils/notifications";
import {AxiosError} from "axios";
import {getAxios} from "../../utils/axios";
import "./RecoverEnd.scss";

type RecoverEndForm = {
    password: string;
    confirm_password: string;
    token: string;
};

function RecoverEnd() {
    const navigate = useNavigate();
    const location = useLocation();

    const recoverEndSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required'),
        confirm_password: Yup.string()
            .required('Password confirmation is required')
            .oneOf([Yup.ref('password')], 'Password and confirmation must match'),
    });

    const {
        register: recoverEndRegister,
        handleSubmit: recoverEndHandleSubmit,
        formState: recoverEndState,
    } = useForm<RecoverEndForm>({
        resolver: yupResolver(recoverEndSchema)
    });
    const recoverEndErrors = recoverEndState.errors;

    const axios = getAxios();

    // watch supplied token
    let params = new URLSearchParams(location.search);
    const [token, setToken] = useState(params.get("token"));
    useEffect(() => {
        recoverEndRegister("token", { value: token === null ? undefined : token });
    }, [token]);

    async function onSubmit (req: RecoverEndForm) {
        try {
            const { data } = await axios.post(
                "/api/auth/recover/end",
                req,
            );

            if (data.status === "failure") {
                Notify.warning(data.message);
            } else if (data.status === "success") {
                Notify.info(data.message);
                navigate("/auth");
            } else {
                Notify.error("Bad response from server")
                console.log(data);
            }

        } catch (error) {
            if (error instanceof AxiosError) {
                const axiosError: AxiosError = error;
                Notify.error(axiosError.message);
            } else {
                Notify.error(String(error));
            }
        }
    }

    return (
        <div className="form-recover-end">
            <div className="form-body shadow-sm bg-body rounded vw-50">
                {token ? (
                    <form onSubmit={recoverEndHandleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label htmlFor="recoverEndPassword" className="form-label">New Password</label>
                            <input type="password" id="recoverEndPassword"
                                   className={`form-control ${recoverEndErrors.password ? 'is-invalid' : ''}`}
                                   {...recoverEndRegister("password")} />
                            <div className="invalid-feedback">{recoverEndErrors.password?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="recoverEndConfirmPassword" className="form-label">Confirm Password</label>
                            <input type="password" id="recoverEndConfirmPassword"
                                   className={`form-control ${recoverEndErrors.confirm_password ? 'is-invalid' : ''}`}
                                   {...recoverEndRegister("confirm_password")} />
                            <div className="invalid-feedback">{recoverEndErrors.confirm_password?.message}</div>
                        </div>
                        <div className="dialog-buttons">
                            <div className="dialog-buttons-left" >
                                <button type="submit" className="btn btn-primary">Set New Password</button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <span>The recovery token was not supplied.</span>
                )}
            </div>
        </div>
    );
}

export default RecoverEnd;
