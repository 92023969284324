
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Notify } from '../../utils/notifications';
import { getAxios } from '../../utils/axios';

import "./Login.scss";
import {AxiosError} from "axios";

import { Link, useLocation } from "react-router-dom";

type LoginForm = {
    email: string;
    password: string;
    rm: string;
};

const shakeTime = 300;

function Login() {
    const location = useLocation();
    const [shake, setShake] = useState(false);
    const shakeLoginForm = () => {
        setShake(true);
        setTimeout(() => setShake(false), shakeTime);
    };

    // Build login form
    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required'),
        rm: Yup.string()
    });
    const {
        register: loginRegister,
        handleSubmit: loginHandleSubmit,
        formState: loginFormState,
        setValue: loginSetValue,
        watch: loginWatch,
    } = useForm<LoginForm>({
        resolver: yupResolver(loginSchema)
    });
    const loginErrors = loginFormState.errors;
    const email = loginWatch("email", "");

    useEffect(() => {
        let email = location.state?.email;
        if (email) {
            loginSetValue("email", email, { shouldValidate: true });
            window.history.replaceState({}, document.title);
        }
    }, []);

    // Get axios
    const axios = getAxios();

    async function onSubmit (req: LoginForm) {
        try {
            const { data } = await axios.post(
                "/api/auth/login",
                req,
            );

            if (data.status === "failure") {
                shakeLoginForm();
                Notify.warning("Invalid credentials");
            } else if (data.status === "success") {
                window.location.replace(data.location);
            } else {
                Notify.error("Bad response from server")
                console.log(data);
            }

        } catch (error) {
            if (error instanceof AxiosError) {
                const axiosError: AxiosError = error;
                Notify.error(axiosError.message);
            } else {
                Notify.error(String(error));
            }
        }
    }

    const onError = () => {
        shakeLoginForm();
    };

    // Emit HTML
    return (
        <div className="form-login">
            <div className={`form-body shadow-sm bg-body rounded vw-50 ${shake ? `shake` : ''}`}>
                <form onSubmit={loginHandleSubmit(onSubmit, onError)}>
                    <div className="mb-3">
                        <label htmlFor="loginEmail" className="form-label">Email address</label>
                        <input type="email" id="loginEmail"
                               className={`form-control ${loginErrors.email ? 'is-invalid' : ''}`}
                               {...loginRegister("email")} />
                        <div className="invalid-feedback">{loginErrors.email?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="loginPassword" className="form-label">Password</label>
                        <input type="password" id="loginPassword"
                               className={`form-control ${loginErrors.password ? 'is-invalid' : ''}`}
                               {...loginRegister("password")} />
                        <div className="invalid-feedback">{loginErrors.password?.message}</div>
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="loginRememberMe"
                               {...loginRegister("rm") } />
                        <label className="form-check-label" htmlFor="loginRememberMe">Remember me</label>
                    </div>
                    <div className="dialog-buttons">
                        <div className="dialog-buttons-left" >
                            <button type="submit" className="btn btn-primary">Log In</button>
                        </div>
                        <div className="dialog-buttons-right" >
                            <Link to="/auth/recover"
                                  state={{email}}>Forgot Password</Link>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default Login;
