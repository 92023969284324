import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import type { RootState } from '../rootReducer';
import {
    ActionMap,
    createSubscribeActionCreator,
    ResponseHandler,
    websocketActions,
    WebSocketRequestParams
} from "./websocket";
import BigNumber from "bignumber.js";
import {CurrencyAmounts} from "../currency";
import {AppThunk} from "../store";

export type ViewDonation = {
    id: string,
    campaignID: string,
    displayName: string,
    donationTime: number,
    amounts: CurrencyAmounts,
    isAnonymous: boolean,
    isPosted: boolean,
};

export type ManageDonation = ViewDonation & {
    fullName: string,
};

export type Donation = ViewDonation | ManageDonation;

type DonationMessage = {
    id: string,
    fullName: string,
    campaignID: string,
    displayName: string,
    donationTime: string,
    amounts: CurrencyAmounts,
    isAnonymous: boolean,
    isPosted: boolean,
};

type Campaign = {
    id: string,
    name: string,
    currencies: string[],
    progress: string,
    goals: string[],
    createdDate: string,
    nrDonations: number,
    totals: CurrencyAmounts,
    isAutoPosted: boolean,
};

type CampaignUpdate = {
    campaign: Campaign,
};

type Donations = { [key: string]: Donation };

type CampaignEvent = {
    index: number,
    type: string,
};

type CampaignState = Campaign & {
    lastEventIdx: number,
    lastEvent: CampaignEvent | undefined,
    donations: Donations,
};

type CampaignStateMessage = {
    campaign: Campaign,
    donations: DonationMessage[],
};

type DonationSetMessage = {
    campaign: Campaign,
    donation: DonationMessage,
};

type CampaignStates = { [key: string]: CampaignState };

type CampaignsState = {
    campaigns: CampaignStates,
};

const initialState: CampaignsState = {
    campaigns: {},
};

type ReqToggleDonation = {
    id: string,
    value: boolean,
};

type RspToggleDonation = ReqToggleDonation & {
    campaignID: string,
};

type ReqToggleCampaign = {
    id: string,
    value: boolean,
};

type RspToggleCampaign = ReqToggleCampaign;

type ReqSetCampaignGoal = {
    id: string,
    goal: string,
};

type RspSetCampaignGoal = ReqSetCampaignGoal;

export const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        setCampaign: (state, action: PayloadAction<Campaign>) => {
            let campaign = action.payload;

            let oldState = state.campaigns[campaign.id];
            let cstate : CampaignState = {
                ...campaign,
                lastEvent: oldState === undefined ? undefined : oldState.lastEvent,
                lastEventIdx: oldState === undefined ? 0 : oldState.lastEventIdx,
                donations: oldState == undefined ? ({} as Donations) : oldState.donations,
            };

            state.campaigns[campaign.id] = cstate;
        },
        setCampaignIsAutoPosted: (state, action: PayloadAction<RspToggleCampaign>) => {
            // look up campaign
            let message = action.payload;
            let campaign = state.campaigns[message.id];
            if (campaign === undefined) {
                return;
            }

            campaign.isAutoPosted = message.value;
        },
        setDonation: (state, action: PayloadAction<DonationMessage>) => {
            // look up campaign
            let donationMessage = action.payload;
            let campaign = state.campaigns[donationMessage.campaignID];
            if (campaign === undefined) {
                return;
            }

            // Convert donation to state object
            let donation: Donation = {
                ...donationMessage,
                donationTime: new Date(donationMessage.donationTime).getTime(),
            };

            // Fire off a donation event
            if (donation.isPosted === true) {
                let idx = campaign.lastEventIdx + 1;
                campaign.lastEventIdx = idx;
                campaign.lastEvent = {
                    index: idx,
                    type: "donation",
                };
            }

            // Update donation data
            campaign.donations[donation.id] = donation;
        },
        setDonationIsAnonymous: (state, action: PayloadAction<RspToggleDonation>) => {
            // look up campaign
            let toggleDonation = action.payload;
            let campaign = state.campaigns[toggleDonation.campaignID];
            if (campaign === undefined) {
                return;
            }

            campaign.donations[toggleDonation.id].isAnonymous = toggleDonation.value;
        },
        setDonationIsPosted: (state, action: PayloadAction<RspToggleDonation>) => {
            // look up campaign
            let toggleDonation = action.payload;
            let campaign = state.campaigns[toggleDonation.campaignID];
            if (campaign === undefined) {
                return;
            }

            campaign.donations[toggleDonation.id].isPosted = toggleDonation.value;
        },
    },
});

export const campaignActions = campaignSlice.actions;

type makeDonationToggleActionParams = {
    donationID: string,
    value: boolean,
    what: string,
    responseHandler: ResponseHandler,
};

function makeDonationToggleAction({donationID, value, what, responseHandler}: makeDonationToggleActionParams) {
    let params: WebSocketRequestParams = {
        type: `campaign/donation/set${what}`,
        payload: {
            id: donationID,

            value: value,
        },
        responseHandler: (store, msg) => {
            responseHandler(store, msg);
        },
    };
    let thunk: AppThunk = (dispatch, getState) => {
        dispatch(websocketActions.sendRequest(params));
    };
    return thunk;
}

type makeCampaignToggleActionParams = {
    campaignID: string,
    value: boolean,
    what: string,
    responseHandler: ResponseHandler,
};

function makeCampaignToggleAction({campaignID, value, what, responseHandler}: makeCampaignToggleActionParams) {
    let params: WebSocketRequestParams = {
        type: `campaign/set${what}`,
        payload: {
            id: campaignID,

            value: value,
        },
        responseHandler: (store, msg) => {
            responseHandler(store, msg);
        },
    };
    let thunk: AppThunk = (dispatch, getState) => {
        dispatch(websocketActions.sendRequest(params));
    };
    return thunk;
}

export function setCampaignGoal(campaignID: string, goal: string) {
    let params: WebSocketRequestParams = {
        type: `campaign/setGoal`,
        payload: {
            id: campaignID,
            goal: goal,
        },
    };
    let thunk: AppThunk = (dispatch, getState) => {
        dispatch(websocketActions.sendRequest(params));
    };
    return thunk;
}

export function setDonationIsAnonymous(donationID: string, value: boolean) {
    return makeDonationToggleAction({
        donationID: donationID,
        value: value,
        what: "Anonymous",
        responseHandler: (store, msg) => {
            store.dispatch(campaignActions.setDonationIsAnonymous(msg));
        },
    });
}

export function setDonationIsPosted(donationID: string, value: boolean) {
    return makeDonationToggleAction({
        donationID: donationID,
        value: value,
        what: "Posted",
        responseHandler: (store, msg) => {
            store.dispatch(campaignActions.setDonationIsPosted(msg));
        },
    });
}

export function setCampaignIsAutoPosted(campaignID: string, value: boolean) {
    return makeCampaignToggleAction({
        campaignID: campaignID,
        value: value,
        what: "AutoPosted",
        responseHandler: (store, msg) => {
            store.dispatch(campaignActions.setCampaignIsAutoPosted(msg));
        },
    });
}

type ReqCampaignSubscribe = {
    id: string,
    mode: string,
};

export const [ campaignSubscribe, campaignUnsubscribe ] = createSubscribeActionCreator({
    itemType: "campaign",
    subscribeResponse: (store, msg) => {
        let campaignState: CampaignStateMessage = msg;

        store.dispatch(campaignActions.setCampaign(campaignState.campaign));
        campaignState.donations.forEach((donation) => {
            store.dispatch(campaignActions.setDonation(donation));
        })
    },
    subscribeMsg: (identity: string, mode: string) => {
        let msgType = `campaign/subscribe`;
        let msg: ReqCampaignSubscribe = {
            id: identity,
            mode: mode,
        };

        return [msgType, msg];
    },
});

export const { setCampaign, setDonation } = campaignSlice.actions;

export function selectCampaign(state: RootState, campaignID: string|undefined): CampaignState | undefined {
    return campaignID === undefined ? undefined : state.campaign.campaigns[campaignID];
}

export function selectCampaignIsAutoPosted(state: RootState, campaignID: string|undefined): boolean | undefined {
    return selectCampaign(state, campaignID)?.isAutoPosted
}

export function selectCampaignGoals(state: RootState, campaignID: string|undefined): { [key: string]: string } | undefined {
    let campaign = selectCampaign(state, campaignID);
    if (campaign === undefined) {
        return undefined;
    }

    let ret: { [key: string]: string } = {};
    campaign.currencies.forEach((c, i) => {
        if (campaign !== undefined) { // silence warning
            ret[c] = campaign.goals[i];
        }
    });

    return ret;
}

export const selectCampaignProgress = createSelector(selectCampaign, campaign => {
    if (campaign === undefined) {
        return undefined;
    }
    return new BigNumber(campaign.progress);
});

export const selectCampaignEvent = createSelector(selectCampaign, campaign => {
    if (campaign === undefined) {
        return undefined;
    }
    return campaign.lastEvent;
});

// Sort by date descending, then by id string for consistency
export const selectSortedDonations = createSelector(selectCampaign, campaign => {
    if (campaign === undefined) {
        return undefined;
    }
    let donations = Array.from(Object.values(campaign.donations));
    return donations.sort((a, b) => {
        return b.donationTime - a.donationTime || a.id.localeCompare(b.id);
    });
});

export default campaignSlice.reducer;

export const campaignWsActions: ActionMap = {
    "campaign/set": (type, payload) => {
        payload = payload as CampaignUpdate;
        return campaignActions.setCampaign(payload.campaign);
    },
    "campaign/donation/set": (type, payload) => {
        return (dispatch, getState) => {
            let setDonationPayload = payload as DonationSetMessage;
            dispatch(campaignActions.setCampaign(setDonationPayload.campaign));
            dispatch(campaignActions.setDonation(setDonationPayload.donation));
        };
    },
};
