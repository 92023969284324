
import { useEffect, useRef, createContext, useContext, useMemo, HTMLAttributes } from 'react';
import { default as axios, AxiosResponse, AxiosRequestConfig } from 'axios';
import {InitJson, selectBaseURL, selectHasInitJson} from "./store/system";
import {store} from "./store";
import {applyInitJson} from "./init";

interface RequestInterceptor {
    (v: AxiosRequestConfig<any>): AxiosResponse<any, any>
}

interface ResponseInterceptor {
    (rsp: AxiosResponse<any, any>): AxiosResponse<any, any>
}

export interface AxiosInstanceProps extends HTMLAttributes<Element> {
    // add any custom props, but don't have to specify `children`
    config?: {},
    requestInterceptors?: RequestInterceptor[],
    responseInterceptors?: ResponseInterceptor[],
}

const AxiosContext = createContext(axios.create());
export const AxiosInstanceProvider = ({
    config = {},
    requestInterceptors = [],
    responseInterceptors = [],
    children,
}: AxiosInstanceProps) => {
    console.log(store.getState());
    let baseURL = selectBaseURL(store.getState());
    let newConfig =  {
        xsrfCookieName: '_csrf',
        xsrfHeaderName: 'X-CSRF-Token',
        baseURL: baseURL,
        ...config
    }
    const instanceRef = useRef(axios.create(newConfig));

    useEffect(() => {
        requestInterceptors.forEach((interceptor) => {
            instanceRef.current.interceptors.request.use(
                interceptor
            );
        });
        responseInterceptors.forEach((interceptor) => {
            instanceRef.current.interceptors.response.use(
                interceptor
            );
        });

        // XXX TODO This is ugly here. Axios needs to move into Redux middleware, but in the meantime
        // this lives here.
        if (selectHasInitJson(store.getState()) == false) {
            instanceRef.current.get("/api/init-json").then(r => {
                if (r.status != 200) {
                    return;
                }

                // TODO validate
                let initJson = r.data.initJson as InitJson;
                console.log(initJson);
                applyInitJson(initJson);
            }).catch(e => console.log("Defatalizing init-json fetch error: ", e));
        }
    }, []);

    return (
        <AxiosContext.Provider value={instanceRef.current}>
            {children}
        </AxiosContext.Provider>
    );
};

export const getAxios = () => {
    const contextInstance = useContext(AxiosContext);
    return useMemo(() => {
        return contextInstance || axios.create();
    }, [contextInstance]);
};
