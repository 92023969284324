import {store} from "./store";
import {InitJson, systemSlice} from "./store/system";
import {userSlice} from "./store/user";
import {websocketSlice} from "./store/websocket";

export function initSystem() {
    // Get root element
    let rootElement = document.getElementById('root') as HTMLElement;

    // Parse Base64 JSON. Note that we use ~ as an empty placeholder to prevent
    // parcel from collapsing data-init-json="" to data-init-json
    let dataInitJson: string | undefined;
    if (rootElement !== undefined) {
        dataInitJson = rootElement.dataset['initJson'];
    }
    if (dataInitJson !== undefined && dataInitJson !== "~") {
        let initJson = JSON.parse(atob(dataInitJson));
        console.log(initJson);
        applyInitJson(initJson);
    }
}

export function applyInitJson(initJson: InitJson) {
    store.dispatch(systemSlice.actions.handleInitJson(initJson));
    store.dispatch(userSlice.actions.handleInitJson(initJson));
    store.dispatch(websocketSlice.actions.connect());
}
