import { Store } from 'react-notifications-component';

export class Notify {
    static error(message: string) {
        Store.addNotification({
            container: "top-right",
            title: "Error",
            message: message,
            type: "danger",
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    static warning(message: string) {
        Store.addNotification({
            container: "top-right",
            title: "Warning",
            message: message,
            type: "danger",
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    static info(message: string) {
        Store.addNotification({
            container: "top-right",
            title: "Information",
            message: message,
            type: "info",
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }
}