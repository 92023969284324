import React from "react";
import ReactDOM from "react-dom/client";
import Login from './components/Login';
import Recover from './components/Recover';
import RecoverEnd from './components/RecoverEnd';
import { AxiosInstanceProvider } from '../utils/axios';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import "@popperjs/core";
import '../scss/moneybomb.scss';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet
} from "react-router-dom";
import {initSystem} from "../utils/init";
import {store} from "../utils/store";
import {Provider} from "react-redux";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AxiosInstanceProvider>
                <Router>
                    <ReactNotifications />
                    <Routes>
                        <Route path="/auth">
                            <Route index element={<Login />} />
                            <Route path="recover">
                                <Route index element={<Recover />} />
                                <Route path="end" element={<RecoverEnd />} />
                            </Route>
                        </Route>
                    </Routes>
                    <div className="auth-container">
                        <Outlet />
                        <div className="text-center">
                        </div>
                    </div>
                </Router>
            </AxiosInstanceProvider>
        </Provider>
    </React.StrictMode>
);
initSystem();
