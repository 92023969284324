import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../rootReducer';
import {InitJson} from "./system";

type UserState = {
    email: string | undefined,
    name: string | undefined,
    id: string | undefined,
}

const initialState: UserState = {
    email: "",
    name: "Username",
    id: "",
};

export type UserInfo = {
    email: string,
    name: string,
    id: string,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        handleInitJson: (state, action: PayloadAction<InitJson>) => {
            // get baseURL from document or ENV
            let initJson = action.payload;
            if (initJson.user !== null) {
                state.email = initJson.user.email;
                state.name = initJson.user.name;
                state.id = initJson.user.id;
            }
        },
        updateUser: (state, action: PayloadAction<UserInfo | null>) => {
            state.email = action.payload?.email;
            state.name = action.payload?.name;
            state.id = action.payload?.id;
        },
    },
});

export const { updateUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;