import {AnyAction, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {userSlice} from "./store/user";
import {InitJson, systemSlice} from "./store/system";
import {makeWebSocketMiddleware, websocketSlice} from "./store/websocket";

import rootReducer, { RootState } from './rootReducer';

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['websocket/sendRequest'],
            },
        }).concat(makeWebSocketMiddleware());
    },
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
    >;


