import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../rootReducer";
import {UserInfo} from "./user";

type Submodules = { [key: string]: any };

export type Version = {
    goVersion: string,
    gitRevision: string,
};

export type InitJson = {
    baseURL: string,
    webSocketURL: string,
    sessionID: string,
    csrfToken: string,
    user: UserInfo | null,
    submodules: Submodules,
    version: Version,
};

type SystemState = {
    baseURL: string,
    webSocketURL: string,
    sessionID: string,
    csrfToken: string,
    hasInitJson: boolean,
    submodules: Submodules,
    version: Version | null,
};

const initialState: SystemState = {
    baseURL: "",
    webSocketURL: "",
    sessionID: "",
    csrfToken: "",
    hasInitJson: false,
    submodules: {},
    version: null,
};

export const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        handleInitJson: (state, action: PayloadAction<InitJson>) => {
            // get baseURL from document or ENV
            let initJson = action.payload;
            let baseURL = initJson["baseURL"];
            let envBaseURL = process.env['PUBLIC_URL'];
            if (envBaseURL !== undefined) {
                baseURL = envBaseURL;
            }
            state.baseURL = baseURL

            state.webSocketURL = initJson["webSocketURL"];

            state.hasInitJson = true;

            state.sessionID = initJson["sessionID"];
            state.csrfToken = initJson["csrfToken"];

            state.submodules = initJson["submodules"];

            state.version = initJson["version"];
        },
        setBaseURL: (state, action: PayloadAction<string>) => {
            state.baseURL = action.payload;
        },
        setWebSocketURL: (state, action: PayloadAction<string>) => {
            state.webSocketURL = action.payload;
        },
    },
});

export const selectSystem = (state: RootState) => state.system;
export const selectBaseURL = (state: RootState) => selectSystem(state).baseURL;
export const selectWebSocketURL = (state: RootState) => selectSystem(state).webSocketURL;
export const selectHasInitJson = (state: RootState) => selectSystem(state).hasInitJson;
export const selectSessionID = (state: RootState) => selectSystem(state).sessionID;
export const selectCSRFToken = (state: RootState) => selectSystem(state).csrfToken;
export const selectSubmodule = (state: RootState, name: string) => selectSystem(state).submodules[name];
export const selectVersion = (state: RootState): Version | null => selectSystem(state)?.version;

export default systemSlice.reducer;
